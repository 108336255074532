import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const BlogPosts = () => {
  return (
    <div className="xl:mx-auto xl:container">
      <div className="lg:px-20 md:px-6 px-4 md:py-12 py-8">
        <div className="flex flex-col-reverse lg:flex-row items-center">
          <div className="w-full lg:w-1/2 md:py-9 py-6">
            <figure className="lg:w-full h-full object-cover object-center w-full">
              <StaticImage
                src="../images/website/Blog/darkling-beetle-banner.jpg"
                alt="Life Cycle of a Darkling Beetle (Mealworm)"
                placeholder="tracedSVG"
                layout="constrained"
              />
            </figure>
          </div>
          <div className="lg:w-1/2 lg:pl-12 lg:pr-24">
            <p className="text-sm leading-none text-lime-600 pb-2">
              Featured Article
            </p>
            <p className="md:text-3xl lg:text-4xl text-2xl font-semibold lg:leading-9 text-gray-800 lg:pb-6 md:pb-4 pb-2">
              Life Cycle of a Darkling Beetle (Mealworm)
            </p>
            <p className="text-sm leading-5 text-gray-600 md:pb-10 pb-8">
              Darkling beetles (also known as Mealworm Beetles) are prolific
              breeders. It is harder to stop them breeding than it is to get
              them to breed.
            </p>
            <p className="text-sm leading-5 text-gray-600 md:pb-10 pb-8">
              Learn more about the life cycle of a mealworm.
            </p>
            <div className="md:block flex items-center justify-center">
              <Link
                to="/blog/life-cycle-of-a-darkling-beetle-mealworm/"
                className="lg:w-auto w-full border border-lime-800 hover:text-gray-50 hover:bg-lime-800 focus:outline-none lg:px-10 px-7 lg:py-4 py-3 text-sm leading-none text-lime-800 "
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPosts
