import React from "react"
import Layout from "components/Layout"
import BlogPosts from "components/BlogPosts"
import Seo from "components/SEO"

const blog = () => {
  return (
    <div>
      <Seo
        title="Blog | Learn about Mealworms for Pets | Mandatory Insects"
        description="Learn About Mealworms in our blog"
      />
      <Layout>
        <div>
          <BlogPosts />
        </div>
      </Layout>
    </div>
  )
}

export default blog
